import React, { useRef } from 'react';
import { UserAvatar } from 'components/UserAvatar';
import Popover from 'components/lib/Popover';
import { Avatar } from 'components/lib/Avatar/';
import { useScrollableParent } from 'hooks/useScrollableParent';
import { ScrollableParentSeekMode } from 'hooks/useScrollableParent/enums';
import { AVATARS_GROUP, AVATARS_GROUP_MORE_AVATAR } from 'utils/testIds';
import { AvatarGroupPopover } from './components/AvatarGroupPopover';
import { useAvatarGroupStyles } from './AvatarGroup.styles';
import { AvatarGroupProps } from './AvatarGroup.types';

/**
 * Displays a group of avatars. If the number of all items is greater than visible items count, the
 * rest of the avatars are displayed in a popover that is triggered by the "more" button.
 */
export const AvatarGroup = ({
  items,
  fallbackDetailsEndpoint,
  nonInteractiveAvatars = false,
  visibleItemsLimit = 5,
  getPopupContainer,
}: AvatarGroupProps) => {
  const styles = useAvatarGroupStyles();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { getScrollableParent } = useScrollableParent(
    wrapperRef,
    undefined,
    ScrollableParentSeekMode.AnyClosest
  );

  // Deleted items are only displayed in the popover
  const itemsVisible = items
    .filter(item => !item.isDeleted)
    .slice(0, visibleItemsLimit);
  const itemsHidden = items.filter(
    item => !itemsVisible.some(visibleItem => visibleItem.id === item.id)
  );

  return (
    <div
      ref={wrapperRef}
      className={styles.wrapper}
      data-testid={AVATARS_GROUP}
    >
      {itemsVisible.map(item =>
        item.id ? (
          <UserAvatar
            key={item.userGroup ? `group-${item.id}` : `user-${item.id}`}
            id={item.id}
            userGroup={item.userGroup}
            firstName={item.firstName}
            lastName={item.lastName}
            accountType={item.accountType}
            isDeleted={item.isDeleted}
            size='small'
            disablePopover={nonInteractiveAvatars}
            fallbackDetailsEndpoint={fallbackDetailsEndpoint}
            getPopupContainer={getPopupContainer}
          />
        ) : null
      )}

      {itemsHidden.length > 0 && (
        <Popover
          placement='top'
          destroyTooltipOnHide
          trigger='click'
          overlayClassName={styles.popover}
          getPopupContainer={getPopupContainer ?? getScrollableParent}
          content={
            <AvatarGroupPopover
              items={itemsHidden}
              nonInteractiveAvatars={nonInteractiveAvatars}
              fallbackDetailsEndpoint={fallbackDetailsEndpoint}
            />
          }
        >
          <Avatar
            isCountAvatar
            size='small'
            className={styles.moreAvatar}
            data-testid={AVATARS_GROUP_MORE_AVATAR}
            onClick={event => event.stopPropagation()}
          >
            ···
          </Avatar>
        </Popover>
      )}
    </div>
  );
};
