import { useState } from 'react';
import { User } from 'utils/types/api/users.types';
import { UserGroup } from 'utils/types/api/userGroups.types';
import { apiCall } from 'utils/api';
import { generatePath } from 'react-router-dom';
import { USER, USER_GROUP } from 'utils/endpoints';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';

export const useAvatarDetails = (
  id: number | string | undefined,
  groupName: string | undefined,
  fallbackDetailsEndpoint: string | undefined,
  openPopover: () => void
) => {
  const [details, setDetails] = useState<User | UserGroup>();
  const [noViewPermission, setNoViewPermission] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getDetailsFromFallbackEndpoint = async (
    id: number | string,
    fallbackEndpoint: string
  ) => {
    try {
      const result = await apiCall.get(fallbackEndpoint);

      const details =
        result.data._meta[!!groupName ? 'user_groups' : 'users'][id];

      setDetails(details);
      openPopover();
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        showUnhandledErrorToast(error);
        return;
      }

      if (error.response?.status === StatusCodes.FORBIDDEN) {
        setNoViewPermission(true);
        openPopover();
        return;
      }

      showUnhandledErrorToast(error);
    }
  };

  const getAvatarDetails = async () => {
    if (!id) {
      return;
    }

    try {
      setIsLoading(true);

      const result = await apiCall.get(
        generatePath(!!groupName ? USER_GROUP : USER, { id })
      );

      setDetails(result.data);
      openPopover();
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        showUnhandledErrorToast(error);
        return;
      }

      if (error.response?.status === StatusCodes.FORBIDDEN) {
        if (!fallbackDetailsEndpoint) {
          setNoViewPermission(true);
          openPopover();
          return;
        }

        await getDetailsFromFallbackEndpoint(id, fallbackDetailsEndpoint);
        return;
      }

      showUnhandledErrorToast(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { details, isLoading, noViewPermission, getAvatarDetails };
};
