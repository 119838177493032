import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  SelectUserAndGroupOptionMap,
  UsersAndGroupsSelectLimits,
} from 'utils/types/selectInput.types';
import { usePatchObjectRecordUserField } from './hooks/usePatchObjectRecordUserField';
import { useInPlaceEditUserValidation } from './hooks/useInPlaceEditUserValidation';
import { InPlaceEditUserProps } from './InPlaceEditUser.types';
import { useInPlaceEditUserStyles } from './InPlaceEditUser.styles';
import { UsersAndGroupsFormValue } from 'components/FormPreview2/widgets/inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { useObjectClassUserFieldOptions } from 'components/UsersAndGroupsSelection/hooks/useObjectClassUserFieldOptions/useObjectClassUserFieldOptions';
import { UsersAndGroupsExpandableSelect } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect';
import { UsersAndGroupsExpandablePickerRef } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/types';
import {
  createUsersAndGroupsSelectionCountLimits,
  userAndGroupOptionMapToUserFormValue,
} from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/utils';
import { isEqual, isNil } from 'lodash';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import { MinMaxInfoLabelType } from 'components/MinMaxInfo/types';
import InPlaceEditWrapper from '../components/InPlaceEditWrapper';
import { useSelectOptionAvatars } from 'hooks/avatars/useSelectOptionAvatars';
import { convertIdsToUserAndGroupOptions } from 'components/FormPreview2/utils/convertIdsToUserAndGroupOptions';
import { useUserFormFieldSelectionOptions } from 'components/FormPreview2/widgets/hooks';
import { USERS_AND_GROUPS_FIELD_EDIT_CONTENT_TESTID } from 'utils/testIds';
import { SearchBarDisplay } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/types/searchBarDisplay';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { useObjRecordUserFieldDetails } from 'components/UsersAndGroupsSelection/hooks/useObjRecordUserFieldOptions';
import { createSelectionFromMetadata } from './utils/createSelectionFromMetadata';
import { AvatarGroup } from 'components/AvatarGroup';
import { generatePath } from 'react-router-dom';
import { OBJECT_RECORD_FIELD_DETAILS } from 'utils/endpoints';

/**
 * Allows users and groups selection while editing records in the "in place edit" style where a popover
 * allows the user to make the changes and apply them when closing it.
 */
export const InPlaceEditUser = ({
  label = '',
  value,
  disabled,
  propertyName,
  patchUrl,
  readOnly,
  size,
  withUnderline,
  required,
  fieldId,
  objectClassId,
  recordId,
  minUsers,
  maxUsers,
  minGroups,
  maxGroups,
  allowGroupMemberSelection,
  allowGroupSync,
  onSaveSuccess,
  meta: objectRecordMetadata,
}: InPlaceEditUserProps<ObjectRecordDetails>) => {
  const styles = useInPlaceEditUserStyles();

  const usersAndGroupsExpandablePickerRef = useRef<UsersAndGroupsExpandablePickerRef | null>(
    null
  );

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selection, setSelection] = useState<SelectUserAndGroupOptionMap>({
    users: new Map(),
    groups: new Map(),
  });
  const [tempValue, setTempValue] = useState<UsersAndGroupsFormValue | null>(
    value ?? null
  );

  const {
    isLoading: areOptionsLoading,
    options,
    fetchOptions,
  } = useObjectClassUserFieldOptions(fieldId ?? null, objectClassId);
  const {
    isLoading: areFieldDetailsLoading,
    valueMetadata,
    fetchFieldDetails,
  } = useObjRecordUserFieldDetails(recordId, propertyName);
  const {
    patchUserField,
    errors: apiErrors,
    isSaving,
  } = usePatchObjectRecordUserField(patchUrl, objectClassId, propertyName);

  const limits: UsersAndGroupsSelectLimits = {
    selectionCountLimits: createUsersAndGroupsSelectionCountLimits(
      minUsers,
      maxUsers,
      minGroups,
      maxGroups
    ),
    isFieldRequired: required ?? false,
    isAllowedToSelectGroupMembers: allowGroupMemberSelection ?? false,
    isAllowedToSyncGroups: allowGroupSync ?? false,
  };

  const {
    validateFulfillmentPossibility,
    validateField,
    errors,
    tooltip,
    clearErrors,
    minGroupsError,
    minUsersError,
  } = useInPlaceEditUserValidation(label, apiErrors, limits);

  const {
    getCurrentOptions,
    onLoadedUserOptions,
  } = useUserFormFieldSelectionOptions(options);

  const userAndGroupOptions = convertIdsToUserAndGroupOptions(
    value?.users ?? [],
    value?.user_groups ?? [],
    getCurrentOptions(),
    objectRecordMetadata
  );

  const { avatars } = useSelectOptionAvatars(
    userAndGroupOptions.users,
    userAndGroupOptions.groups
  );

  const hasValue = value?.users.length || value?.user_groups.length;

  const handleSetSelection = useCallback(
    (newSelection: React.SetStateAction<SelectUserAndGroupOptionMap>) => {
      setSelection(prev => {
        const value =
          typeof newSelection === 'function'
            ? newSelection(prev)
            : newSelection;

        const newFormValue = userAndGroupOptionMapToUserFormValue(value);

        setTempValue(newFormValue);
        return value;
      });
    },
    [setTempValue]
  );

  const onDropdownOpenChange = useCallback(
    async isOpen => {
      if (!isOpen) {
        setIsEditing(false);
        return;
      }

      if (disabled) {
        return;
      }

      // Fetching methods have internal caching, they will resolve immediately if the data is already fetched
      const [options, details] = await Promise.all([
        fetchOptions(),
        fetchFieldDetails(false),
      ]);

      if (!options || !details) {
        return;
      }

      validateFulfillmentPossibility(
        value,
        options,
        details.valueMetadata,
        details.isMinUsersFulfilled
      );

      setSelection(createSelectionFromMetadata(details.valueMetadata));
    },
    [
      disabled,
      value,
      fetchFieldDetails,
      fetchOptions,
      validateFulfillmentPossibility,
    ]
  );

  const restoreOriginalValue = useCallback(() => {
    setTempValue(value ?? null);
    setSelection(createSelectionFromMetadata(valueMetadata));
  }, [valueMetadata, value]);

  /**
   * Restores the original field state and closes the dropdown to reject any changes the user
   * could have done while editing the field.
   */
  const rejectChanges = useCallback(() => {
    restoreOriginalValue();
    clearErrors();

    if (usersAndGroupsExpandablePickerRef.current) {
      usersAndGroupsExpandablePickerRef.current.blur();
    }
  }, [clearErrors, restoreOriginalValue]);

  /**
   * Handles the intent to close the dropdown when the user tries to save the changes by clicking away from it.
   * @returns Returns true if the dropdown should be allowed to close, false otherwise.
   */
  const onCloseIntent = useCallback(async () => {
    if (isEqual(tempValue, value)) {
      clearErrors();
      return true;
    }

    if (!validateField(tempValue) || errors.length > 0) {
      return false;
    }

    try {
      const responseData = await patchUserField(tempValue);

      if (isNil(responseData)) {
        return false;
      }

      // Refetch field datails data after successful save, so it contains up-to-date value metadata
      fetchFieldDetails(true);

      if (onSaveSuccess) {
        onSaveSuccess(responseData, tempValue);
      }

      if (usersAndGroupsExpandablePickerRef.current) {
        usersAndGroupsExpandablePickerRef.current.blur();
      }

      return true;
    } catch (error) {
      rejectChanges();
      showUnhandledErrorToast(error);
      return true;
    }
  }, [
    errors.length,
    tempValue,
    value,
    clearErrors,
    fetchFieldDetails,
    onSaveSuccess,
    patchUserField,
    rejectChanges,
    validateField,
  ]);

  useEffect(() => {
    if (!usersAndGroupsExpandablePickerRef.current || !isEditing) {
      return;
    }

    usersAndGroupsExpandablePickerRef.current.focus();
  }, [isEditing]);

  return (
    <div className={styles.userFieldWrapper}>
      <InPlaceEditWrapper
        label={label}
        required={!!required}
        readOnly={readOnly}
        disabled={disabled}
        withUnderline={withUnderline ?? false}
        isEditMode={isEditing}
        isSaving={isSaving}
        tooltipText={tooltip}
        rowAdditionalClassName={styles.editContentContainer}
        onViewClick={() => {
          if (disabled || readOnly) {
            return;
          }

          setIsEditing(true);
        }}
        size={size}
        noAfter={true}
        viewContent={
          <div data-testid={`users-viewcontent-${label}`}>
            {hasValue ? (
              <AvatarGroup
                items={avatars}
                fallbackDetailsEndpoint={generatePath(
                  OBJECT_RECORD_FIELD_DETAILS,
                  { id: recordId, fieldAlias: propertyName }
                )}
              />
            ) : (
              '-'
            )}
          </div>
        }
        editContent={
          <UsersAndGroupsExpandableSelect
            ref={usersAndGroupsExpandablePickerRef}
            isLoadingOptions={areOptionsLoading || areFieldDetailsLoading}
            selection={selection}
            setSelection={handleSetSelection}
            onDropdownOpenChange={onDropdownOpenChange}
            onGroupMembersLoad={onLoadedUserOptions}
            options={options}
            limits={limits}
            required={required}
            isFieldDisabled={disabled || readOnly}
            withUnderline={false}
            errors={errors}
            searchBarBehavior={SearchBarDisplay.Always}
            testId={`${USERS_AND_GROUPS_FIELD_EDIT_CONTENT_TESTID}${label}`}
            editModeOptions={{
              isSaving: isSaving,
              onCloseIntent: onCloseIntent,
              onRejection: rejectChanges,
            }}
            minMaxInfoErrorPairs={
              new Map<MinMaxInfoLabelType, string>([
                [MinMaxInfoLabelType.Users, minUsersError],
                [MinMaxInfoLabelType.Groups, minGroupsError],
              ])
            }
          />
        }
      />
    </div>
  );
};
