import React, { useRef } from 'react';
import clsx from 'clsx';
import { Avatar } from 'components/lib/Avatar';
import { Loader } from 'components/lib/Loader';
import Popover from 'components/lib/Popover';
import useAvatarColor from 'hooks/useAvatarColor';
import { useScrollableParent } from 'hooks/useScrollableParent';
import { ScrollableParentSeekMode } from 'hooks/useScrollableParent/enums';
import { AccountTypeOptions } from 'pages/Users/enums';
import { USER_AVATAR_TESTID } from 'utils/testIds';
import tryParseInt from 'utils/functions/tryParseInt';
import { User } from 'utils/types/api/users.types';
import { UserGroup } from 'utils/types/api/userGroups.types';
import { UserDetails } from './components/UserDetails';
import { UserGroupDetails } from './components/UserGroupDetails';
import { useAvatarDetails } from './hooks/useAvatarDetails';
import { useUserAvatarPopover } from './hooks/useUserAvatarPopover';
import { useUserAvatarOnClick } from './hooks/useUserAvatarOnClick';
import { getAvatarInitials } from './utils/getAvatarInitials';
import { useUserAvatarStyles } from './UserAvatar.styles';
import { UserAvatarProps } from './UserAvatar.types';

export const UserAvatar = ({
  id,
  firstName,
  lastName,
  userGroup,
  isDeleted,
  size,
  disablePopover = false,
  getPopupContainer,
  scrollableParentId,
  closeOnElementScroll,
  accountType,
  disabled,
  isExtraLarge,
  className,
  fallbackDetailsEndpoint,
  ...rest
}: UserAvatarProps) => {
  const avatarBgColor = useAvatarColor(
    typeof id === 'string' ? Math.abs(tryParseInt(id, 1)) : Math.abs(id || 1),
    isDeleted
  );
  const styles = useUserAvatarStyles({
    avatarBgColor,
    size,
    isUserGroup: !!userGroup,
    disablePopover,
    isDeleted,
    isOneTimeCompletionUser:
      accountType === AccountTypeOptions.OneTimeCompletion,
    isExtraLarge,
  });

  const elementRef = useRef<HTMLDivElement>(null);

  const { getScrollableParent } = useScrollableParent(
    elementRef,
    scrollableParentId ? [scrollableParentId] : undefined,
    scrollableParentId
      ? ScrollableParentSeekMode.PredefinedList
      : ScrollableParentSeekMode.AnyClosest
  );
  const { isPopoverVisible, closePopover, openPopover } = useUserAvatarPopover(
    closeOnElementScroll
  );
  const {
    details,
    isLoading,
    noViewPermission,
    getAvatarDetails,
  } = useAvatarDetails(id, userGroup, fallbackDetailsEndpoint, openPopover);
  const { handleClick } = useUserAvatarOnClick(
    details === undefined,
    isPopoverVisible,
    disablePopover,
    isDeleted,
    getAvatarDetails,
    openPopover
  );

  const initials = getAvatarInitials(firstName, lastName, userGroup, isDeleted);

  return (
    <div>
      <Popover
        overlayStyle={{ maxWidth: 'min(100%, 228px)' }}
        overlayClassName={styles.popover}
        visible={isPopoverVisible}
        placement='top'
        destroyTooltipOnHide
        getPopupContainer={getPopupContainer || getScrollableParent}
        content={
          !!userGroup ? (
            <UserGroupDetails
              groupData={
                (details ?? {
                  id,
                  name: userGroup,
                  description: '',
                  num_of_members: 0,
                }) as UserGroup
              }
              noViewPermission={noViewPermission}
              closePopover={closePopover}
            />
          ) : (
            <UserDetails
              userData={
                (details ?? {
                  id,
                  first_name: firstName,
                  last_name: lastName,
                  account_type: accountType,
                }) as User
              }
              noViewPermission={noViewPermission}
              closePopover={closePopover}
            />
          )
        }
      >
        <Loader spinning={isLoading} wrapperClassName={styles.loader}>
          <Avatar
            ref={elementRef}
            className={clsx(styles.avatar, className, {
              [styles.disabledOneTimeCompletionAvatar]:
                disabled &&
                accountType === AccountTypeOptions.OneTimeCompletion,
            })}
            size={size}
            data-testid={USER_AVATAR_TESTID}
            onClick={handleClick}
            {...rest}
          >
            {initials}
          </Avatar>
        </Loader>
      </Popover>
    </div>
  );
};
